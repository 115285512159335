<template>
  <div :class="[styles.root, props.customClass]">
    <swiper
        :class="[styles.swiper]"
        :navigation="props.navigation ? swiperNavigation : false"
        :pagination="props.pagination ? swiperPagination : false"
        :modules="[Navigation, Pagination]"
        :breakpoints="swiperBreakpoints"
    >
      <swiper-slide v-for="(slide, index) in props.slides" :key="index">
        <component v-bind="slide.props" :is="slide.component"/>
      </swiper-slide>
      
      <template v-if="props.navigation">
        <div :class="props.customClassBaseNavigation">
          <div :class="[styles.swiperButtonNext, 'swiper-button-next']"/>
          <div :class="[styles.swiperButtonPrev, 'swiper-button-prev']"/>
        </div>
      </template>
    </swiper>

    <template v-if="props.pagination">
      <div :class="[styles.swiperPaginationCustom, props.customClassPagination, 'swiper-pagination']"/>
    </template>

    <div v-if="props.navigationCustom" :class="styles.customNavigation">
      <button :class="[styles.swiperCustomButtonPrev, 'custom-prev']" @click="goPrev">
        <IconArrow/>
      </button>
      <div :class="styles.slideCount">
        <Typography variant="body-1" tag="span">{{ lastVisibleSlideIndex }}/{{ totalSlides }}</Typography>
      </div>
      <button :class="[styles.swiperCustomButtonNext, 'custom-next']" @click="goNext">
        <IconArrow/>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import IconArrow from '~/shared/assets/icons/icon-arrow-slider.svg';
import { nextTick, onMounted, ref } from 'vue';
import styles from './Slider.module.scss';
import { Typography } from '~/shared/ui/Typography';

interface SliderProps {
  slides: any,
  navigation?: boolean;
  navigationCustom?: boolean;
  pagination?: boolean;
  desktopSlides: number;
  tabletSlides: number;
  mobileSlides: number;
  desktopSpaceBetween?: number;
  tabletSpaceBetween?: number;
  mobileSpaceBetween?: number;
  customClass?: string;
  customClassPagination?: string;
  customClassBaseNavigation?: string;
}

const props = withDefaults(defineProps<SliderProps>(), {
  navigation: false,
  navigationCustom: false,
  pagination: false,
  desktopSlides: 1,
  tabletSlides: 1,
  mobileSlides: 1,
  desktopSpaceBetween: 0,
  tabletSpaceBetween: 0,
  mobileSpaceBetween: 0,
  customClass: '',
  customClassPagination: '',
  customClassBaseNavigation: '',
});

const swiperNavigation = {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
};

const swiperPagination = {
  el: '.swiper-pagination',
  clickable: true,
  type: 'bullets',
};

const swiperBreakpoints = {
  1024: {
    slidesPerView: props.desktopSlides,
    spaceBetween: props.desktopSpaceBetween,
  },
  768: {
    slidesPerView: props.tabletSlides,
    spaceBetween: props.tabletSpaceBetween,
  },
  0: {
    slidesPerView: props.mobileSlides,
    spaceBetween: props.mobileSpaceBetween,
  },
};

const swiperInstance = ref(null);

onMounted(async () => {
  await nextTick();

  swiperInstance.value = document.querySelector('.swiper').swiper;

  const baseNextEl = document.querySelector('.swiper-button-next');
  const basePrevEl = document.querySelector('.swiper-button-prev');
  const customNextEl = document.querySelector('.custom-next');
  const customPrevEl = document.querySelector('.custom-prev');

  if (baseNextEl && basePrevEl) {
    swiperInstance.value.params.navigation.nextEl = baseNextEl;
    swiperInstance.value.params.navigation.prevEl = basePrevEl;
    swiperInstance.value.navigation.init();
    swiperInstance.value.navigation.update();
  }

  if (customNextEl && customPrevEl) {
    swiperInstance.value.params.navigation.nextEl = customNextEl;
    swiperInstance.value.params.navigation.prevEl = customPrevEl;
    swiperInstance.value.navigation.init();
    swiperInstance.value.navigation.update();
  }
});

const goNext = () => {
  swiperInstance.value.slideNext();
};

const goPrev = () => {
  swiperInstance.value.slidePrev();
};

const currentIndex = ref(1);
const totalSlides = ref(props.slides.length);
const visibleSlides = ref(1);
const lastVisibleSlideIndex = ref(1);

const calculateVisibleSlides = () => {
  if (window.innerWidth >= 1024) {
    return props.desktopSlides;
  } else if (window.innerWidth >= 768) {
    return props.tabletSlides;
  } else {
    return props.mobileSlides;
  }
};

onMounted(() => {
  const swiper = document.querySelector('.swiper').swiper;

  const updateVisibleSlides = () => {
    const slidesPerView = calculateVisibleSlides();
    visibleSlides.value = slidesPerView;

    currentIndex.value = swiper.activeIndex + 1;
    lastVisibleSlideIndex.value = Math.min(currentIndex.value + slidesPerView - 1, totalSlides.value);
  };

  swiper.on('init', updateVisibleSlides);
  swiper.on('resize', updateVisibleSlides);

  swiper.on('slideChange', () => {
    currentIndex.value = swiper.activeIndex + 1;
    lastVisibleSlideIndex.value = Math.min(currentIndex.value + visibleSlides.value - 1, totalSlides.value);
  });

  swiper.init();
});
</script>
